<template>
  <div class="add-good common-bg">
    <van-nav-bar :title="title" left-arrow @click-left="getBack" />
    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <van-field
          required
          maxlength="10"
          label="商品名称"
          v-model="trade_name"
        />
      </van-cell-group>
      <van-cell-group inset>
        <van-field required label="商品价格" v-model="trade_price" />
      </van-cell-group>
      <van-cell-group inset>
        <van-field label="商品图片">
          <template #input>
            <van-uploader
              :after-read="afterRead"
              :before-read="beforeRead"
              v-model="shop_home_img"
            />
          </template>
        </van-field>
      </van-cell-group>
    </van-form>
    <van-button type="primary" size="normal" @click="onSubmit">提交</van-button>
  </div>
</template>

<script>
import { addGoodsMsg, upload } from "@/utils/api.js";
export default {
  data() {
    return {
      title: "添加商品",
      list: [],
      isShowLoading: false,
      trade_name: "", //商品名称
      trade_price: "", //商品价格
      shop_home_img: [], //商品首页图
      //   addMsg: {
      //     trade_name: "", //商品名称
      //     trade_price: "", //商品价格
      //     shop_home_img: [{}], //商品首页图
      //   },
    };
  },
  methods: {
    getBack() {
      this.$router.go(-1);
    },
    onSubmit() {
      const shop_home_img = this.img;
      const data = {
        trade_name: this.trade_name,
        trade_price: this.trade_price,
        shop_home_img: this.img,
      };
      addGoodsMsg(data).then((res) => {
        if (res.code === 200) {
          this.$router.push("/user/goodManage");
        }
      });
    },
    beforeRead(file) {
      if (
        file.type !== "image/jpg" &&
        file.type !== "image/jpeg" &&
        file.type !== "image/png"
      ) {
        this.$toast("请上传jpg或png格式的图片");
        return false;
      }
      return true;
    },
    afterRead(file) {
      const formData = new FormData();
      formData.append("file", file.file);
      upload(formData).then((res) => {
        if (res.code === 200) {
          this.$toast("上传成功");
          this.img = res.data;
        } else {
          this.$toast("上传失败");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.van-cell-group {
  margin-bottom: 20px;
}

.van-button {
  width: 200px;
  margin: 0 auto;
  text-align: center;
  border-radius: 10px;
  display: inherit;
}
</style>